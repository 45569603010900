export const mxConfig = {
  apiKey: "AIzaSyDJImw0nqvfEhKt9POJRuteOEVRXZyA27g",
  authDomain: "parti-2020.firebaseapp.com",
  databaseURL: "https://parti-2020.firebaseio.com",
  projectId: "parti-2020",
  storageBucket: "parti-2020.appspot.com",
  messagingSenderId: "959324853924",
  appId: "1:959324853924:web:0fdb1b2838fb9147b53831",
  measurementId: "G-84TGWTQ100",
};
export const univTomorrowConfig = {
  apiKey: "AIzaSyDI0RscAhKAmoCqe8Ot2vSKdcyZLVfB5GE",
  authDomain: "mx-univ-tomorrow.firebaseapp.com",
  databaseURL: "https://mx-univ-tomorrow.firebaseio.com",
  projectId: "mx-univ-tomorrow",
  storageBucket: "mx-univ-tomorrow.appspot.com",
  messagingSenderId: "290813281396",
  appId: "1:290813281396:web:323d2ab311021d676efd21",
  measurementId: "G-Z8JVND68BP",
};
export const seoulCityArchitectureConfig = {
  apiKey: "AIzaSyA4QzmZW1_TkmtuO7J7I4sHvCiqAJNN9js",
  authDomain: "seoul-city-architecture.firebaseapp.com",
  databaseURL: "https://seoul-city-architecture.firebaseio.com",
  projectId: "seoul-city-architecture",
  storageBucket: "seoul-city-architecture.appspot.com",
  messagingSenderId: "950181780823",
  appId: "1:950181780823:web:01cc2563e38c0c8379f212",
  measurementId: "G-3G74MQ4WG2",
};
export const sharentingFirebaseConfig = {
  apiKey: "AIzaSyBva_XB1zPdgWcal0-h4ZCDzNbTGhEh_bU",
  authDomain: "sharenting-mix.firebaseapp.com",
  databaseURL: "https://sharenting-mix.firebaseio.com",
  projectId: "sharenting-mix",
  storageBucket: "sharenting-mix.appspot.com",
  messagingSenderId: "1048382260087",
  appId: "1:1048382260087:web:f0e449cc8ce63ea21f9772",
  measurementId: "G-FQGE3EQ6N4",
};
export const ywcaFirebaseConfig = {
  apiKey: "AIzaSyAM58GQXT-HzPRQHadKUwhBC-VefHtZn64",
  authDomain: "ywca-mix.firebaseapp.com",
  databaseURL: "https://ywca-mix.firebaseio.com",
  projectId: "ywca-mix",
  storageBucket: "ywca-mix.appspot.com",
  messagingSenderId: "756888703873",
  appId: "1:756888703873:web:e3ddb177f47b5dc4f18e80",
  measurementId: "G-GV6Z9X80QK",
};
export const policyFair = {
  apiKey: "AIzaSyAPaJ3OGvqVEVoTFFfU9ON4SKcfQdq4t5w",
  authDomain: "policy-fair-mix.firebaseapp.com",
  databaseURL: "https://policy-fair-mix.firebaseio.com",
  projectId: "policy-fair-mix",
  storageBucket: "policy-fair-mix.appspot.com",
  messagingSenderId: "636422424475",
  appId: "1:636422424475:web:73b245e758dc628a14c33e",
  measurementId: "G-ZD6NW82J00"
};